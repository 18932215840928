<template>
  <b-overlay variant="white" :show="show" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
    <b-row>
      <b-col cols="12">
        <uye-bilgi-kart :uyeBilgi="uyeData.uye" />
      </b-col>
    </b-row>

    <b-tabs align="left">
      <b-tab>
        <template #title>
          <feather-icon icon="LayersIcon" />
          <span>Rezervasyonlar</span>
        </template>
        <b-card-text>
          <b-row>
            <b-col cols="12">
              <uye-rezervasyonlar />
            </b-col>
          </b-row>
        </b-card-text>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="DollarSignIcon" />
          <span>Odemeler</span>
        </template>
        <b-card-text>
          <b-row>
            <b-col cols="12">
              <uye-odemeler />
            </b-col>
          </b-row>
        </b-card-text>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="TwitchIcon" />
          <span>Yorumlar</span>
        </template>
        <b-card-text>
          <b-row>
            <b-col cols="12">
              <uye-yorumlar :uyeData="uyeData.uye" />
            </b-col>
          </b-row>
        </b-card-text>
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>

<script>
import UyeBilgiKart from './component/uyeBilgiKart';
import UyeRezervasyonlar from './component/uyeRezervasyonlar';
import UyeOdemeler from './component/uyeOdemeler';
import UyeYorumlar from './component/uyeYorumlar';
export default {
  components: {
    UyeBilgiKart,
    UyeRezervasyonlar,
    UyeOdemeler,
    UyeYorumlar,
  },
  data: () => ({
    show: false,
    uyeData: {
      uye: {},
    },
  }),
  async created() {
    await this.handlerGetData();
  },
  methods: {
    async handlerGetData() {
      if (this.$route.params.k_no) {
        this.show = true;
        const k_no = this.$route.params.k_no;
        this.uyeData = await this.$store.dispatch('uyeFindOne', k_no);

        this.show = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
